import React from "react"

import Layout from "../components/layout-static"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Video from "../components/video"

const Page = () => {
  const metadata = {
    title: "Connecticut Car Accident Lawyer",
    heroH1: "Car Accident Lawyer",
    heroH2: "14 Key Car Accident Questions",
    heroImage: "blg-van-accident.jpg",
    heroImageAlt:
      "Photo of an automobile accident involving a van and a bicycle",
    description:
      "Injured in a car accident? Our experienced lawyers at Bartlett & Grippe, LLC fight for your rights and compensation so that you can focus on recovery. Contact us now.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="uppercase">14 Critical Car Accident Questions</h2>
            <div className="w-full md:w-1/2 mb-8 float-right">
              <Video videoSrcURL="https://www.youtube.com/embed/dQK3NRCQLI8?rel=0" />
            </div>
            <div>
              <p className="mb-8">
                Your top-rated Connecticut car accident lawyer answers very
                important legal questions we’ve been repeatedly asked over the
                last fourteen years. The answers can greatly change the outcome
                and value of your case. If you have additional questions call us
                today for a free, no-obligation consultation with our top rated
                car accident lawyers.
              </p>
              <div className="mb-8">
                <StaticImage
                  quality="100"
                  src="../images/top-attorney-car-accident.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Rated 10 Top Car Accident Attorney"
                />
              </div>
            </div>
            <div className="mb-16">
              <h2>1) WHAT IS MY CAR ACCIDENT CASE WORTH?</h2>
              <p className="mb-8">
                First of all, it’s especially important to realize this is a
                complex process. Despite what’s on the internet, there isn’t a
                magic formula to evaluate a case. Never assume you don’t have a
                case.{" "}
                <strong>
                  Always contact an experienced attorney as soon as possible after
                  any serious accident.
                </strong>{" "}
                Critical evidence and information may be lost if you delay.
              </p>
              <p className="mb-8">
                The value of your case depends on many facts, such as how the
                accident happened, what injuries you sustained, and how these
                injuries affected your life, physically, emotionally and
                financially. Finally, the facts of your case can’t change but how
                Bartlett & Grippe uncovers the facts and presents them can make a
                huge difference in the outcome of your case. An experienced car
                accident lawyer at our firm can help you determine the maximum
                value of your case.
              </p>
              <p className="mb-8 text-2xl">
                <Link to="/firm-overview/practice-areas/personal-injury/eight-costly-mistakes-to-avoid/">
                  Eight costly mistakes personal injury clients often make.
                </Link>
              </p>
              <Link to="/contact-us/">
                <button className="mb-8 cta-button-green">
                  Contact us today for a free confidential consultation
                </button>
              </Link>
            </div>
            <div className="mb-16">
              <h2>
                2) DO I HAVE TO TALK TO THE OTHER DRIVER’S INSURANCE COMPANY?
              </h2>
              <p className="mb-8">
                Do not talk to the other driver’s insurance company
                representatives without first speaking to an experienced attorney
                at Bartlett & Grippe, LLC.
              </p>

              <h2>3) WHEN TO GET AN ATTORNEY FOR A CAR ACCIDENT?</h2>
              <p className="mb-8">
                Not all car accidents require an attorney. You should contact an
                experienced car accident attorney if there are injuries or there
                is major property damage. If you’re not sure, call us and we can
                help. We will walk you through the complex legal process and help
                ensure you receive all you deserve.
              </p>

              <h2>
                4) CAN I BRING AN AUTO ACCIDENT LAWSUIT IF I’M PARTLY TO BLAME?
              </h2>
              <p className="mb-8">
                Yes. Connecticut uses comparative fault rules. Let’s say we go to
                court, and after hearing all the evidence the jury finds that you
                were thirty percent responsible for the accident and the defendant
                was seventy percent at fault. In other words, if you presented
                medical bills, lost wages and other reasonable expenses totaling
                $100,000, then under the modified comparative negligence rule the
                court would reduce your award by the percentage the jury found you
                at fault for the car accident. In this example, you should receive
                $70,000. Unfortunately, if the court finds that you were fifty-one
                percent at fault or higher, you receive nothing, and you owe your
                attorney nothing.
              </p>

              <h2>
                5) SHOULD I CALL A CAR ACCIDENT LAWYER FROM THE SCENE OF THE
                ACCIDENT?
              </h2>
              <p className="mb-8">
                Yes, once police and medical emergency personnel have been
                notified. You should call an experienced car accident attorney
                from the scene of the accident if there are injuries or there is
                major property damage. Calling from the scene is especially
                relevant and can have a major impact on the outcome of a potential
                claim or lawsuit. You need to contact an experienced attorney with
                medical staff to sort through the legal and medical issues. We
                will stand up to the insurance companies and exhaust every
                possible means of recovery for you and your loved ones. We will
                take the case to trial if that’s needed.
              </p>

              <h2>6) DO I HAVE TO NOTIFY THE POLICE?</h2>
              <p className="mb-8">
                You must notify police right away when there is an injury,{" "}
                <Link to="/ct-wrongful-death/">death</Link>, or property damage.
                Most people don’t realize there’s no threshold in CT of estimated
                property damage for reporting a car accident.
              </p>

              <h2>
                7) WHAT DO I NEED TO DO IF I’M IN A CAR CRASH IN CONNECTICUT?
              </h2>
              <p className="mb-8">It depends on the severity of the accident.</p>

              <h3 className="uppercase">Car Accidents with Injury</h3>
              <p className="mb-8">
                Your response to an accident with injuries can help save lives and
                speed rescue personnel to the scene:
              </p>
              <ul className="list-decimal list-outside ml-6 mb-8">
                <li>
                  Notify police and other emergency personnel right away. First
                  call 911, and then call a car accident lawyer at Bartlett &
                  Grippe, LLC.
                </li>
                <li>
                  Don’t try to move an injured person unless there is a vehicle
                  fire or other immediate risk.
                </li>
                <li>
                  Also, covering an injured person with a blanket can help prevent
                  shock.
                </li>
              </ul>

              <h3 className="uppercase">Reporting a Car Accident</h3>
              <p className="mb-8">
                It’s important to realize that collecting information after an
                accident will be helpful to police, your insurance company when
                you make a claim, and especially your car crash attorney at
                Bartlett & Grippe, LLC.
              </p>
              <ul className="list-decimal list-outside ml-6 mb-8">
                <li>Write down the names and addresses of everyone involved.</li>
                <li>
                  Get insurance information, license plate numbers and the make,
                  model and year of vehicles.
                </li>
                <li>
                  Write down any damage you observe to all vehicles related to the
                  accident. Cooperate with police in answering questions and
                  providing information.
                </li>
                <li>Don’t give opinions. State only the facts.</li>
                <li>
                  If your car hits a parked vehicle, try to find the owner. Leave
                  a note on the driver’s windshield if the owner is not around.
                  Include your contact information and the date and time the
                  accident occurred.
                </li>
                <li>
                  Write down as much information about the accident as possible
                  while it’s still fresh in your mind so that we will have all the
                  facts required to mount a strong case.
                </li>
                <li>
                  For several weeks document all of your injuries associated with
                  the accident and include re-injured preexisting injuries. It is
                  quite common to feel fine initially but start to feel pain in a
                  day or two.{" "}
                  <strong>
                    Finally and most importantly, a medical professional must
                    document all of your injuries.
                  </strong>
                </li>
              </ul>

              <h2>8) WHAT INFORMATION SHOULD I GIVE THE POLICE?</h2>
              <p className="mb-8">
                You must show the following documents to the police:
              </p>
              <ul className="list-decimal list-outside ml-6 mb-8">
                <li>Driver’s license</li>
                <li>Registration card</li>
                <li>Connecticut insurance card</li>
                <li>Current address</li>
              </ul>
              <p className="mb-8">
                Bartlett & Grippe, LLC will be able to get a copy of the official
                accident report.
              </p>

              <h2>9) WHAT ARE CAR ACCIDENT LAWYER FEES?</h2>
              <p className="mb-8">
                At our firm, there are no fees or expenses unless you win or you
                accept a settlement. We pay all expenses, court fees, expert
                expenses, etc. You only pay us if you accept a settlement or
                receive an award at trial.
              </p>

              <h2>
                10) SHOULD I TAKE AN OFFER AND WHO MAKES THE DECISION TO TAKE AN
                OFFER?
              </h2>
              <p className="mb-8">
                This is <em>your</em> ultimate decision. We will only advise you
                to accept or reject an offer, based on our years of experience. If
                the offer is unfair and we feel strongly that we will do better
                going to court, we will recommend that we take your case to trial.
                The car accident lawyer that has handled your case from the
                beginning will be representing you in court. You have the final
                say to accept an offer or go to court.
              </p>

              <h2>
                11) HOW LONG DO I HAVE TO FILE A CAR ACCIDENT LAWSUIT? WHAT IS THE
                STATUTE OF LIMITATIONS IN CT?
              </h2>
              <p className="mb-8">
                If you were injured in an auto accident or want to file a suit for
                property damage (compensation for repairs to your car), Per{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.cga.ct.gov/current/pub/chap_926.htm#sec_52-584"
                >
                  Conn. Gen. Stat. Ch. 926 Sec. 52-584
                </a>{" "}
                you have two years from the date of the accident to file a
                lawsuit. The sooner you contact our firm the better. We will
                collect valuable information that can greatly change the outcome
                of your case.
              </p>

              <h2>12) HAS YOUR FIRM HANDLED MANY CAR ACCIDENT CASES?</h2>
              <p className="mb-8">
                Yes, we are{" "}
                <Link to="/ct-wrongful-death/">
                  a nationally recognized personal injury wrongful death law firm
                </Link>
                . Serious car accident lawsuits are one of our firm’s core
                personal injury practice areas. Our car crash attorneys have
                successfully represented hundreds injured in car accidents. We are
                a litigation law firm which means we are the attorneys that
                actually take your case to court if it doesn’t settle to your
                satisfaction. Many firms refer their cases to us if their case
                must go to trial. We treat every case as if it will go to trial.
                As a result, this extra effort helps maximize the outcome whether
                you accept an offer or eventually go to trial. Every case is
                different and past results are not an indication of future
                results.
              </p>

              <h2>13) DO I NEED A CAR ACCIDENT ATTORNEY NEAR ME?</h2>
              <p className="mb-8">
                We are centrally located in Connecticut. We represent people
                injured in car accidents from all Connecticut towns and in all of
                Connecticut’s courts. Distance is not an issue in this age of
                electronics. In addition, over the years we have established a
                network of top-rated experienced attorneys across the country that
                we know and have worked with regularly. Many attorneys across the
                country refer cases to our firm. Don’t settle for less. Contact us
                now.
              </p>
              <p className="mb-8">
                The map shows the numbers and locations of those we’ve helped in
                CT just in the past four years.
              </p>
              <div className="mb-8 text-center">
                <StaticImage
                  quality="100"
                  src="../images/CT-car-accident-lawyer.jpg"
                  formats={["auto", "webp", "avif"]}
                  alt="Serving all major areas in Connecticut"
                />
              </div>

              <h2>14) I WAS REAR-ENDED, WHY WOULD I NEED A LAWYER?</h2>
              <p className="mb-8">
                Rear-end collision accidents - these seem straightforward, but
                without an attorney, insurance companies will try to minimize the
                amount they pay. Having a knowledgeable attorney on your side will
                help to ensure you receive the recovery you deserve. Here’s a real
                life example of one of our clients that thought she could handle
                the case on her own. The story was written up in the Connecticut
                Law Tribune and Attorney Bartlett provided the story behind the
                story here:{" "}
                <Link to="/firm-overview/news-archive/">
                  How Cheshire Attorney Secured $225,000 Settlement for Woman’s
                  Wrist Injuries
                </Link>
              </p>
              <Link to="/contact-us/">
                <button className="mb-8 mx-auto cta-button-green">
                  Contact us today for a free confidential consultation
                </button>
              </Link>
            </div>
            <div className="mb-16">
              <h2 className="uppercase">Connecticut Accident Information</h2>
              <p className="mb-8">
                The State of Connecticut keeps track of the average Daily Vehicles
                Miles Travelled (DVMT) and the number and types of crashes. The
                last compiled data by the State was for 2015 and published in
                2017. It showed that in 2015 the DVMT was a staggering 86.6
                million miles a day driven on Connecticut roads. There were 3.53
                crashes per million DVMT, and of those crashes, there were .24
                fatal crashes per billion DVMT.
              </p>
              <p className="mb-8">
                In 2015 there were 5,182 crashes in Hartford alone, of which 9
                were fatal crashes with 10 fatalities. There was 1752 injury
                crashes with 2,635 injuries.
              </p>
              <p className="mb-8">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://portal.ct.gov/-/media/DOT/documents/dpolicy/ctaf/2015CTCrashFactsBookpdf.pdf?la=en"
                >
                  The above data can be found in the 2015 CT Crash Facts Book{" "}
                </a>
              </p>
              <p className="mb-8">
                When you get in touch with one of the top* personal injury and car
                accident attorneys you start the process of making yourself whole
                again. Many insurance companies will try to settle quickly for
                less than your case is worth. There’s nothing wrong with taking a
                settlement if it is fair, but it’s important to realize you need
                an experienced car accident lawyer on your side. We will work on
                your behalf to achieve fair compensation for your injuries.
              </p>
              <h3 className="uppercase">
                Many accidents bring added complexities such as:
              </h3>
              <ul className="list-disc list-outside ml-6 mb-8">
                <li>
                  Distracted Driver Accident, cell phone accident, and texting
                  while driving accident. Too often today, drivers use cell phones
                  and text while driving. When texting or cell phone use leads to
                  an accident, a distracted driver accident attorney at Bartlett &
                  Grippe can help you recover the compensation you deserve.
                </li>
                <li>
                  Hit and Run Accident cases often leave victims with serious
                  injuries and many questions about what can be done. By
                  contacting a hit and run accident attorney at our firm, you will
                  learn how your own insurance may provide you an avenue to
                  recover from your injuries. If you have been injured, you are
                  not alone, contact us today and let a hit and run accident
                  lawyer at our firm guide you on the road to the recovery you
                  deserve.
                </li>
                <li>
                  Drunk Driver Accident – If you or a loved one was injured by
                  someone intoxicated there may be several sources to recover
                  money for your loss or injury such as the person or
                  establishment that served the driver drinks. This falls under
                  what’s called Dram Shop Law. Call Bartlett & Grippe today for a
                  free consultation to discuss what can be done in your particular
                  case since every case is different and we have handled hundreds
                  of cases.
                </li>
              </ul>

              <h3 className="uppercase">Unique Car Accident Lawyer Experience</h3>
              <p className="mb-8">
                Our expert car accident attorneys possesse unique and valuable
                insight gained from experience in the field of insurance company
                defense. Working as insurance defense attorneys allows us to gain
                valuable knowledge about how insurance adjusters handle and
                evaluate claims. We learned first-hand the maximum amount
                insurance adjusters would pay. We use this knowledge and the
                experience gained from hundreds of car accident cases to help
                maximize your compensation and get you a fair and just settlement.
              </p>
              <p className="mb-8">
                It’s not just about physical damage. A serious car accident can
                leave emotional scars that take a long time to heal. You don’t
                have to settle for less than you deserve when it comes to
                compensation for a car accident. Our lawyers and staff can help
                protect your interests and maximize your compensation. Don’t
                accept less than you should from the other party’s insurance
                company. They want to settle for the lowest amount possible, but
                you deserve better than that and we can help you get what you
                deserve.
              </p>
              <p className="mb-8">
                We serve auto accident clients throughout Connecticut, including
                but not limited to Waterbury, Cheshire, New Haven, Fairfield,
                Bridgeport, and Hartford.
              </p>
              <p className="mb-8">
                Choosing the best Connecticut accident attorney can be a difficult
                job, so{" "}
                <Link to="/firm-overview/best-ct-accident-attorney/">
                  please consider these 12 questions when choosing a car accident
                  attorney by clicking here
                </Link>
                .
              </p>

              <h3>
                CAR ACCIDENT LAWYER CASE REFERRAL – Please see link on our firm
                overview page.
              </h3>

              <p className="mb-8">
                Our mission at Bartlett & Grippe, LLC is to continue to be the
                best Connecticut car accident law firm and have the highest client
                satisfaction.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
